<template>
  <Html :lang="head.htmlAttrs.lang" :dir="head.htmlAttrs.dir">
      <PageHead :title="title" :head="head" />
      <Body>
        <div class="flex flex-col min-h-screen overflow-hidden bg-slate-100">
            <slot name="header">
                <Header/>
            </slot>

            <!-- Page content -->
            <main class="grow">
              <slot>
              </slot>
            </main>
            <slot name="footer">
                <LazyFooter/>
            </slot>
          <LazyDialogFeedback :open="feedbackFormDialog"/>
          <LazyDialogCookieBanner/>
        </div>
      </Body>
  </Html>
</template>

<script setup>
import {storeToRefs} from "pinia";
import {useUtilsStore} from "~/store/utils";
const route = useRoute()
const { t } = useI18n();

const head = useLocaleHead({
  addDirAttribute: true,
  identifierAttribute: 'id',
  addSeoAttributes: true,

})
const title = ref(route.meta.title || t('soundmadeseen') );

const utilsStore = useUtilsStore();
const {feedbackFormDialog} = storeToRefs(utilsStore);
</script>

<style scoped>

</style>